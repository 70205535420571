import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.section`
    display: grid;
    margin-top: 4rem;
    line-height: 1.6;
    grid-template-columns:
        [full-start] minmax(3.6rem, auto) [wide-start] minmax(auto, 240px)
        [main-start] min(720px, calc(100% - 3.6rem * 2)) [main-end] minmax(
            auto,
            240px
        )
        [wide-end] minmax(3.6rem, auto) [full-end];
    @media (max-width: 767px) {
        margin-top: 3.2rem;
        grid-template-columns:
            [full-start] minmax(2rem, auto) [wide-start] minmax(auto, 240px)
            [main-start] min(720px, calc(100% - 2rem * 2)) [main-end] minmax(
                auto,
                240px
            )
            [wide-end] minmax(2rem, auto) [full-end];
    }
    a {
        text-decoration: underline;
        color: #8acdd6;
        &:hover {
            opacity: 0.7;
        }
    }
    > h2 {
        font-size: 2.8rem;
    }
    > h3 {
        font-size: 2.4rem;
        font-weight: 600;
    }
    > [id]:not(:first-child) {
        margin-top: 4.8rem;
    }
    > [id] + * {
        margin-top: 1.6rem;
    }
    > [id] {
        margin: 0;
    }
    > p,
    ul {
        font-size: 1.8rem;
        font-family: Lora, Georgia, Times, serif;
        line-height: 1.6;
    }
    > * {
        grid-column: main-start/main-end;
        > img {
            margin-left: auto;
            margin-right: auto;
            height: auto;
            display: block;
        }
        > figcaption {
            > a {
                color: #15171a;
                text-decoration: none;
            }
            text-align: right;
            color: #999;
            font-size: 1.4rem;
            line-height: 1.4;
            margin-top: 1.6rem;
        }
    }
    > * + * {
        margin-bottom: 0;
        margin-top: 2.8rem;
    }
    > iframe {
        margin-top: 4rem;
        border: 0;
        display: block;
        overflow: hidden;
        width: 100%;
    }
    > blockquote {
        font-family: var(--font-serif);
        &:not([class]) {
            font-size: 1.8rem;
            font-family: Lora, Georgia, Times, serif;
            border-left: 4px solid #8acdd6;
            padding-left: 2rem;
        }
    }
    > .kg-blockquote-alt {
        @media (max-width: 600px) {
            font-size: 1.2em;
            padding-left: 1.75em;
            padding-right: 1.75em;
        }
        margin-top: 4rem;
        font-size: 1.5em;
        color: #979797;
        font-style: normal;
        font-weight: 600;
        line-height: 1.7em;
        text-align: center;
        margin-bottom: 0;
        padding: 0 2.5em;
        border: none;
        strong {
            font-weight: bold;
        }
    }
    > .kg-bookmark-card {
        position: relative;
        width: 100%;
        margin-top: 4rem;
        > .kg-bookmark-container {
            border: 1px solid rgb(124 139 154/25%);
            display: flex;
            min-height: 148px;
            color: var(--color-base);
            font-family: var(--font-sans-serif);
            text-decoration: none;
            border-radius: 3px;
            box-shadow: 0 2px 5px -1px rgb(0 0 0 / 15%), 0 0 1px rgb(0 0 0 / 9%);
            > .kg-bookmark-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                flex-basis: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 20px;
                overflow: hidden;
                > .kg-bookmark-title {
                    font-size: 1.5rem;
                    line-height: 1.4em;
                    font-weight: 600;
                }
                > .kg-bookmark-description {
                    display: -webkit-box;
                    overflow-y: hidden;
                    margin-top: 3px;
                    max-height: 48px;
                    color: color(var(--color-secondary) l(-10%));
                    font-size: 1.5rem;
                    line-height: 1.5em;
                    font-weight: 400;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
    .kg-header-card {
        padding: 12vmin 4em;
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .kg-style-light {
        background-color: #fafafa;
    }
    .kg-size-small {
        padding-top: 14vmin;
        padding-bottom: 14vmin;
        min-height: 40vh;
    }
    .kg-style-image {
        position: relative;
        background-color: #e7e7e7;
        background-size: cover;
        background-position: center;
        &:before {
            position: absolute;
            display: block;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.2));
        }
    }
    .kg-width-full {
        grid-column: full-start/full-end;
    }
    h2.kg-header-card-header,
    h3.kg-header-card-subheader {
        z-index: 999;
        opacity: 1;
        font-size: 1.25em;
        margin: 0.35em 0 0;
        max-width: 40em;
        font-weight: 500;
        line-height: 1.4em;
        strong {
            font-weight: 600;
        }
        a {
            color: #fff;
        }
    }
    h3.kg-header-card-subheader {
        font-family: var(--font-serif);
    }

    h2 + a.kg-header-card-button,
    h3 + a.kg-header-card-button {
        margin: 1.75em 0 0;
    }

    .kg-header-card:not(.kg-style-light) h2.kg-header-card-header,
    .kg-header-card:not(.kg-style-light) h3.kg-header-card-subheader {
        color: #fff;
    }
    .kg-header-card.kg-style-accent {
        background-color: var(--ghost-accent-color);
    }
    .kg-header-card h2.kg-header-card-header {
        font-size: 5em;
        font-weight: 700;
        line-height: 1.1em;
        letter-spacing: -0.01em;
        margin: 0;
        font-size: 4em;
        strong {
            font-weight: 800;
        }
    }
    .kg-header-card a.kg-header-card-button {
        display: flex;
        position: static;
        align-items: center;
        fill: #fff;
        background: #fff;
        border-radius: 3px;
        outline: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        font-size: 1.05em;
        font-weight: 600;
        line-height: 1em;
        text-align: center;
        text-decoration: none;
        letter-spacing: 0.2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #151515;
        height: 2.7em;
        padding: 0 1.2em;
        transition: opacity 0.2s ease;
    }
    .kg-header-card.kg-style-light a.kg-header-card-button {
        background: var(--ghost-accent-color);
        color: #fff;
    }
    .kg-header-card h2 + a.kg-header-card-button,
    .kg-header-card h3 + a.kg-header-card-button {
        margin: 1.75em 0 0;
    }
`;

const ContentContainer = ({ html }) => {
    return (
        <Container
            className="gh-content"
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    );
};

ContentContainer.propTypes = {
    html: PropTypes.string.isRequired,
};

export default ContentContainer;
