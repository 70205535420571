import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";
import InternalLink from "components/InternalLink";

import Pagination from "components/Pagination";
import GhostHead from "components/ghost/GhostHead";
import { Layout } from "../components";
import Content from "../components/Content";
import { formatDate, parseDateTime } from "../../../utils/dateTool";

const ReadNextContainer = styled.div`
    margin-top: 80px;
    display: grid;
    grid-template-columns:
        [full-start] minmax(3.6rem, auto) [wide-start] minmax(auto, 240px)
        [main-start] min(720px, calc(100% - 3.6rem * 2)) [main-end] minmax(
            auto,
            240px
        )
        [wide-end] minmax(3.6rem, auto) [full-end];
    @media (max-width: 767px) {
        margin-top: 64px;
        grid-template-columns:
            [full-start] minmax(2rem, auto) [wide-start] minmax(auto, 240px)
            [main-start] min(720px, calc(100% - 2rem * 2)) [main-end] minmax(
                auto,
                240px
            )
            [wide-end] minmax(2rem, auto) [full-end];
    }
    > * {
        grid-column: main-start/main-end;
    }
`;

const ReadNextHeader = styled.section`
    margin-bottom: 48px;
    max-width: none;
    display: flex;
    flex-direction: column;
    > h4 {
        align-items: center;
        display: flex;
        font-size: 1.8rem;
        color: #15171a;
        letter-spacing: -0.02em;
        line-height: 1.15;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0;
        &:after {
            background-color: #e6e6e6;
            content: "";
            flex-grow: 1;
            height: 1px;
            margin-left: 1.6rem;
            box-sizing: border-box;
        }
    }
`;

const CardContainer = styled.div`
    justify-items: center;
    display: grid;
    gap: 32px 40px;
    row-gap: 48px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: 767px) {
        row-gap: 32px !important;
    }
`;

const CardGrid = styled.article`
    grid-column: 1/3;
    max-width: 720px;
    width: 100%;
    word-break: break-word;
`;

const CardLink = styled(InternalLink)`
    display: flex;
    gap: 24px;
    color: #15171a;
    &:hover {
        text-decoration: none;
        opacity: 0.7;
    }
`;

const CardFigure = styled.figure`
    flex-shrink: 0;
    /* height: 100%; */
    position: relative;
    width: 128px;
    margin: 0;
    @media (min-width: 768px) {
        width: 160px;
    }
    @media (max-width: 767px) {
        width: 100px;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const CardWrapper = styled.div`
    margin-top: -3px;
`;

const CardTitle = styled.h3`
    font-size: 2rem;
    letter-spacing: -0.015em;
    line-height: 1.3;
    margin: 0;
    font-weight: bold;
    @media (max-width: 767px) {
        font-size: 1.6rem;
    }
`;

const CardExcerpt = styled.div`
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1.5rem;
    margin-top: 4px;
    overflow-y: hidden;
    font-family: Lora, Georgia, Times, serif;
    @media (max-width: 767px) {
        display: none;
    }
`;

const CardFooter = styled.footer`
    margin-top: 12px;
    align-items: center;
    color: #999;
    display: flex;
    font-size: 1.1rem;
    font-weight: 500;
    gap: 6px;
    text-transform: uppercase;
    @media (max-width: 767px) {
        margin-top: 8px;
    }
`;

const CardSep = styled.span`
    background-color: currentColor;
    border-radius: 50%;
    height: 2px;
    width: 2px;
`;

/* eslint-disable react/prop-types */
export const Head = ({ data, location }) => {
    const page = data.ghostPostPage;
    const { post } = page;
    const metaProps = { data: { ghostPost: post }, location, type: "article" };
    return (
        <>
            <style type="text/css">{`${post.codeinjection_styles}`}</style>

            <GhostHead metaProps={metaProps} />
        </>
    );
};
/* eslint-enable react/prop-types */

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

const Post = ({ data }) => {
    const page = data.ghostPostPage;
    const { post } = page;
    const relatedPosts = data.allGhostPost.edges;
    return (
        <Layout>
            <main className="site-main">
                <Content
                    data={post}
                    html={page.html}
                    showAuthor
                    showFeatureImage={page.index === 0}
                />
                <Pagination pages={post.pagination} currentPage={page.index} />
                <ReadNextContainer>
                    <ReadNextHeader>
                        <h4>{"Read next"}</h4>
                    </ReadNextHeader>
                    <div>
                        <CardContainer>
                            {relatedPosts.map((article) => {
                                return (
                                    <CardGrid key={article.node.slug}>
                                        <CardLink to={`/${article.node.slug}/`}>
                                            {article.node.feature_image && (
                                                <CardFigure className="gh-card-image">
                                                    <img
                                                        src={
                                                            article.node
                                                                .feature_image
                                                        }
                                                        alt={article.node.title}
                                                    />
                                                </CardFigure>
                                            )}
                                            <CardWrapper>
                                                <header>
                                                    <CardTitle>
                                                        {article.node.title}
                                                    </CardTitle>
                                                </header>
                                                {article.node.excerpt && (
                                                    <CardExcerpt>
                                                        {article.node.excerpt}
                                                    </CardExcerpt>
                                                )}
                                                <CardFooter>
                                                    <span>
                                                        {"by "}
                                                        {
                                                            article.node
                                                                .primary_author
                                                                .name
                                                        }
                                                    </span>
                                                    <CardSep />
                                                    <time
                                                        dateTime={parseDateTime(
                                                            article.node
                                                                .published_at_pretty
                                                        )}
                                                    >
                                                        {formatDate(
                                                            article.node
                                                                .published_at_pretty
                                                        )}
                                                    </time>
                                                </CardFooter>
                                            </CardWrapper>
                                        </CardLink>
                                    </CardGrid>
                                );
                            })}
                        </CardContainer>
                    </div>
                </ReadNextContainer>
            </main>
        </Layout>
    );
};

Post.propTypes = {
    pageContext: PropTypes.shape({
        html: PropTypes.array,
        pageIndex: PropTypes.number,
    }).isRequired,
    data: PropTypes.shape({
        ghostPostPage: PropTypes.shape({
            index: PropTypes.number,
            html: PropTypes.string,
            post: PropTypes.shape({
                codeinjection_styles: PropTypes.object,
                title: PropTypes.string.isRequired,
                feature_image: PropTypes.string,
                slug: PropTypes.string.isRequired,
                pagination: PropTypes.arrayOf(PropTypes.string).isRequired,
            }).isRequired,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!, $url: String!, $tag: String) {
        ghostPostPage(url: { eq: $url }) {
            index
            html
            post {
                feature_image_caption
                ...GhostPostFields
            }
        }
        allGhostPost(
            filter: {
                slug: { ne: $slug }
                tags: { elemMatch: { name: { eq: $tag } } }
                authors: { elemMatch: { slug: { ne: "gliacloud" } } }
            }
            limit: 3
            sort: { fields: published_at, order: DESC }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
