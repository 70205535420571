import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import InternalLink from "components/InternalLink";
import { formatDate, parseDateTime } from "../../../../utils/dateTool";
import ContentContainer from "./ContentContainer";

const Header = styled.header`
    justify-items: start;
    position: relative;
    display: grid;
    grid-template-columns:
        [full-start] minmax(var(--header-gap), auto) [wide-start] minmax(
            auto,
            240px
        )
        [main-start] min(
            var(--content-width, 720px),
            calc(100% - var(--header-gap) * 2)
        )
        [main-end] minmax(auto, 240px) [wide-end] minmax(
            var(--header-gap),
            auto
        )
        [full-end];
    @media (max-width: 767px) {
        grid-template-columns: [full-start] minmax(2rem, auto) [wide-start]
                minmax(auto, 240px) [main-start] min(720px),
            calc(100% - 2rem * 2) [main-end] minmax(auto, 240px) [wide-end]
                minmax(2rem, auto) [full-end];
    }
    > * {
        grid-column: main-start/main-end;
    }
    h1 {
        color: var(--color-darker-gray);
        letter-spacing: -0.02em;
        line-height: 1.15;
        margin: 0;
    }
`;

const PrimaryTag = styled(InternalLink)`
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.01em;
    margin-bottom: 12px;
    text-transform: uppercase;
    color: #8acdd6;
`;
const Title = styled.h1`
    font-size: var(--title-size);
    color: var(--color-darker-gray);
    letter-spacing: -0.02em;
    line-height: 1.15;
    margin: 0;
    @media (max-width: 767px) {
        font-size: 3.2rem !important;
    }
`;
const AuthorContainer = styled.aside`
    grid-column: wide-start/main-start;
    left: 0;
    padding-right: 32px;
    position: absolute;
    @media (max-width: 1199px) {
        align-items: center;
        -moz-column-gap: 12px;
        column-gap: 12px;
        display: grid;
        grid-column: main-start/main-end;
        grid-template-columns: auto 1fr;
        margin-top: 24px;
        padding-right: 0;
        position: static;
    }
`;
const AuthorFigure = styled.figure`
    grid-row: 1/3;
    height: 48px;
    width: 48px;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    div {
        background-color: #e6e6e6;
    }
    img {
        object-fit: cover;
        /* position: absolute; */
        width: 100%;
        height: 100%;
        display: block;
    }
`;
const AuthorName = styled.h4`
    font-size: 1.8rem;
    letter-spacing: -0.01em;
    color: #15171a;
    line-height: 1.15;
    margin: 0;
    font-weight: bold;
    a {
        text-decoration-skip-ink: auto;
        color: #15171a;
    }
`;
const PostMeta = styled.div`
    margin-top: 6px;
    align-items: center;
    color: #999;
    display: flex;
    font-size: 1.3rem;
    letter-spacing: -0.003em;
    @media (max-width: 1199px) {
        line-height: 1;
        margin-top: 0;
    }
`;
const PostExcerpt = styled.p`
    font-size: 1.9rem;
    font-family: var(--font-serif);
    color: var(--color-secondary-text);
    line-height: 1.5;
    margin: 0;
    margin-top: 2rem;
`;
const PostSep = styled.span`
    background-color: currentColor;
    border-radius: 50%;
    height: 2px;
    width: 2px;
    margin: 0 6px;
`;
const PostFigure = styled.figure`
    width: 100%;
    margin-top: 4rem;
    @media (max-width: 767px) {
        margin-top: 2.4rem;
    }
    > img {
        width: 100%;
        height: auto;
        display: block;
        max-width: 100%;
    }
`;
const FigCaption = styled.figcaption`
    color: var(--color-secondary-text);
    font-size: 1.3rem;
    text-align: right;
    line-height: 1.4;
    margin-top: 1.6rem;
    a {
        color: var(--color-darker-gray);
        text-decoration: none;
        &:hover {
            opacity: 0.8;
        }
    }
`;

const Content = ({
    data,
    html,
    showAuthor = false,
    showFeatureImage = true,
}) => {
    return (
        <>
            <Header>
                {data.primary_tag && (
                    <PrimaryTag to={`/tag/${data.primary_tag.slug}/`}>
                        {data.primary_tag.name}
                    </PrimaryTag>
                )}

                <Title>{data.title}</Title>

                {showAuthor && (
                    <AuthorContainer>
                        <AuthorFigure>
                            <div>
                                <img
                                    src={
                                        data.primary_author.profile_image ||
                                        "/icons/avatar.svg"
                                    }
                                    alt={data.primary_author.name}
                                />
                            </div>
                        </AuthorFigure>
                        <AuthorName>
                            <InternalLink
                                to={`/author/${data.primary_author.slug}/`}
                            >
                                {data.primary_author.name}
                            </InternalLink>
                        </AuthorName>
                        <PostMeta>
                            <time dateTime={parseDateTime(data.published_at)}>
                                {formatDate(data.published_at)}
                            </time>
                            {data.reading_time && (
                                <>
                                    <PostSep />
                                    <span>{`${data.reading_time} min`}</span>
                                </>
                            )}
                        </PostMeta>
                    </AuthorContainer>
                )}

                {data.custom_excerpt && (
                    <PostExcerpt>{data.custom_excerpt}</PostExcerpt>
                )}

                {data.feature_image && showFeatureImage && (
                    <PostFigure>
                        <img
                            src={data.feature_image + '?size="xl"'}
                            alt={data.title}
                            srcSet={`
                    ${data.feature_image + '?size="s"'} 300w,
                    ${data.feature_image + '?size="m"'} 720w,
                    ${data.feature_image + '?size="l"'} 960w,
                    ${data.feature_image + '?size="xl"'} 1200w,
                    ${data.feature_image + '?size="xxl"'} 2000w,
                    `}
                        />
                        {data.feature_image_caption && (
                            <FigCaption
                                dangerouslySetInnerHTML={{
                                    __html: data.feature_image_caption,
                                }}
                            />
                        )}
                    </PostFigure>
                )}
            </Header>
            <ContentContainer html={html} />
        </>
    );
};
Content.propTypes = {
    data: PropTypes.shape({
        published_at: PropTypes.string,
        primary_tag: PropTypes.shape({
            slug: PropTypes.string,
            name: PropTypes.string,
        }),
        primary_author: PropTypes.shape({
            name: PropTypes.string,
            profile_image: PropTypes.string,
            slug: PropTypes.string,
        }),
        reading_time: PropTypes.string,
        title: PropTypes.string,
        custom_excerpt: PropTypes.string,
        secondary_navigation: PropTypes.array,
        feature_image: PropTypes.string,
        feature_image_caption: PropTypes.string,
        slug: PropTypes.string,
    }),
    html: PropTypes.string.isRequired,
    showAuthor: PropTypes.bool,
    showFeatureImage: PropTypes.bool,
};
export default Content;
